const data = {
    "settings": {
        "template_id": 2,
        "primaryColor": "#C50000",
        "buttonColor": "#FF3434",
        "buttonBg": "#FFDCDC",
        "darkColor": "#000000",
        "seconderyColor": "#ffffff",
        "boxShadow": "0px 30px 80px 5px rgba(209, 43, 40, 0.3)",
        "bggradient": "linear-gradient(180deg, rgba(209, 43, 40, 0.09) 0%, rgba(255, 255, 255, 0.11) 100%)",
        "contactAddress": "linear-gradient(98.63deg, #FFF8F8 0%, rgba(255, 255, 255, 0) 100%)",
    },
    "location": {
        "id": 167,
        "name": "Sushi Kingdom",
        "category": "Restaurant",
        "url": "http://127.0.0.1:5500/index.html",
        "logo": "./img/v1/logo.png",
        "logo_footer": "./img/v1/logo.png",
        "banner": {
            "title": "Sushi Kingdom",
            "description": "We invite you to savor the flavors and immerse yourself in the kingdom of sushi- Where sushi dreams come true!",
            "image": "./img/v1/hero_bg.webp",
            "thumbnail": "./img/v1/hero_image.webp",
            "button_name": "Order Now",
            "button_link": "https://order.kloudeats.com/l/sushi-kingdom-madison/172"
        },
        "gallery": [
            {
                "original": "./img/v1/gallery/1.webp",
                "thumbnail": "/img/v1/gallery/1.webp"
            },
            {
                "original": "./img/v1/gallery/2.webp",
                "thumbnail": "/img/v1/gallery/2.webp"
            }, {
                "original": "./img/v1/gallery/3.webp",
                "thumbnail": "/img/v1/gallery/3.webp"
            }, {
                "original": "./img/v1/gallery/3.webp",
                "thumbnail": "/img/v1/gallery/3.webp"
            }, {
                "original": "./img/v1/gallery/4.webp",
                "thumbnail": "/img/v1/gallery/4.webp"
            }, {
                "original": "./img/v1/gallery/5.webp",
                "thumbnail": "/img/v1/gallery/5.webp"
            }, {
                "original": "./img/v1/gallery/6.webp",
                "thumbnail": "/img/v1/gallery/6.webp"
            },
        ],
        "about": [
            {
                "img": "./img/v1/store.webp",
                "title": "It’s more than just a meal",
                "description": "Welcome to Sushi Kingdom Madison, your go-to all-you-can-eat sushi restaurant in Madison, TN! Indulge in an exquisite culinary experience as we offer an extensive selection of delicious sushi, expertly crafted by our talented chefs.",
            }
        ],
        "reviews": {
            "image": "./img/v1/review.webp",
            "contents": [
                {
                    "review": "Absolutely loved the Sushi Kingdom! This all-you-can-eat sushi spot is a true gem. The sushi selection is fantastic, and the chefs really know their craft. The flavors were delightful, and the presentation was impressive. The staff was attentive and friendly, making the dining experience even more enjoyable. If you're a sushi lover like me, don't miss out on this culinary delight. Highly recommended! Sushi Kingdom Madison is now my go-to spot for sushi cravings.",
                    "reviewer": "Pamela Stella",
                },
                {
                    "review": "Great, all you can eat sushi spots in the area. The food is delicious, everything I tried was good. Their selection is vast and their products are fresh and tasty. Their service is quick and they're very attentive with every guest. Fair all u can eat price. The inside looks very clean, but the washroom ( in particular the washroom doors) wasn't clean enough.",
                    "reviewer": "Jack Lee",
                },
                {
                    "review": "‘’My husband and I loved Sushi Kingdom! Everything was made fresh to order. Everything we had was so delicious! The cheesecake is a must try. Will definitely be coming back and making this our sushi restaurant.",
                    "reviewer": "Smith L.",
                },
                {
                    "review": "‘’You can order as much food as you want. Sushi buffets usually have mediocre quality, but my dad and I were impressed with the food that came out. I can even say the sashimi and rolls were better prepared than some made to order sushi places. I love salmon nigiris, so this was my happy place. I would definitely go again.",
                    "reviewer": "Amanda K",
                }
            ]
        },
        "social_media": [
            {
                icon: "facebook",
                link: "https://www.facebook.com/Sushikingdom.madison"
            },
            {
                icon: "instagram",
                link: "https://instagram.com/Sushikingdom.madison"
            },
        ],
        "contact_us": {
            "phone": 6152398797,
            "address": "2010 Gallatin Pike N, Madison, TN 37115",
            "background": "./img/v1/contactus-banner.webp",
            "business_hours": "Sun:\t\t11:30 AM - 9:30PM\nMon - Thu:\t11 AM – 2:30 PM\n\t\t\t4PM – 9:30 PM\nFri:\t\t\t11am-2:45pm\n\t\t\t4pm-10pm\nSat:\t\t\t11 AM - 10 PM"
        },
    }
}
export default data
